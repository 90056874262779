<template>
  <section id="network">
    <div class="vx-row">
      <div class="vx-col w-full" v-if="haveData">
        <!-- header -->
        <div class="vx-row header">
          <div class="vx-col w-full item-holder">
            <div class="vx-col w-5/12 sm:w-1/3 md:w-1/4 lg:w-1/6">
              <div class="line right"></div>
              <div class="line left"></div>
              <NetworkCard :item="network[0]" color="primary" />
            </div>
          </div>
        </div>

        <!-- step 2 -->
        <div class="vx-row child">
          <div class="vx-col w-full item-holder middle-network-margin">
            <div class="vx-col w-5/12 sm:w-1/3 md:w-1/4 lg:w-1/6">
              <div class="line right"></div>
              <div class="line left"></div>
              <NetworkCard :item="network[1]" color="success" />
            </div>

            <div class="vx-col w-5/12 sm:w-1/3 md:w-1/4 lg:w-1/6">
              <div class="line right"></div>
              <div class="line left"></div>
              <NetworkCard :item="network[2]" color="success" />
            </div>
          </div>
        </div>

        <!-- step 3 -->
        <div class="vx-row child hidden sm:block">
          <div class="vx-col w-full item-holder">
            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[3]" color="danger" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[4]" color="danger" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[5]" color="danger" />
            </div>

            <div class="vx-col w-full sm:w-1/5 md:w-1/6">
              <NetworkCard :item="network[6]" color="danger" />
            </div>
          </div>
        </div>
      </div>
      <div class="vx-col w-full" v-else>
        Noting to show
      </div>
    </div>
  </section>
</template>

<script>
import NetworkCard from "@/components/network/NetworkCard.vue";
import { mapActions, mapGetters } from "vuex";
export default {
  components: { NetworkCard },
  data() {
    return {
      haveData: false
    };
  },

  computed: {
    ...mapGetters({
      network: "moduleNetwork/network"
    })
  },
  methods: {
    ...mapActions({
      getNetwork: "moduleNetwork/getNetwork"
    }),

    async get_Network() {
      // Start Loading
      this.$vs.loading();
      //
      await this.getNetwork().then(res => {
        if (res === true) {
          this.haveData = true;
        } else {
          this.haveData = false;
        }
      });
      // Close Loading
      this.$vs.loading.close();
      //
    }
  },

  mounted() {
    this.get_Network();
  }
};
</script>

<style lang="scss">
@import "./network.scss";
</style>
